/** @define events-type;  weak */
.events-type .item {
  width: 45px;
  height: 45px;
}
.events-type .movie {
  background: url("/images/svg/event_movie.svg") no-repeat center #05d6a0;
  background-size: 24px;
}
.events-type .theater {
  background: url("/images/svg/event_theater.svg") no-repeat center #ef476f;
  background-size: 24px;
}
.events-type .music {
  background: url("/images/svg/event_music.svg") no-repeat center #118ab2;
  background-size: 24px;
}
.events-type .kids {
  background: url("/images/svg/event_kids.svg") no-repeat center #ffd065;
  background-size: 24px;
}
.events-type .art {
  background: url("/images/svg/event_art.svg") no-repeat center #043b4c;
  background-size: 24px;
}
.events-type .private {
  background: url("/images/svg/event_house.svg") no-repeat center #eb1c24;
  background-size: 24px;
}
.events-type .sell {
  background: url("/images/svg/event_sell.svg") no-repeat center #043b4c;
  background-size: 24px;
}
.events-type .dance {
  background: url("/images/svg/event_dance.svg") no-repeat center #ffd065;
  background-size: 24px;
}
.events-type .talking {
  background: url("/images/svg/event_talking.svg") no-repeat center #229660;
  background-size: 24px;
}
.events-type .ball {
  background: url("/images/svg/event_disco.svg") no-repeat center #ffd065;
  background-size: 24px;
}
.events-type .other {
  background: url("/images/svg/event_other.svg") no-repeat center #229660;
  background-size: 24px;
}
@media only screen and (max-width: 999px) {
  .events-type--play {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (min-width: 1000px) {
  .events-type--play .title {
    flex: 1;
  }
}
@media only screen and (max-width: 999px) {
  .events-type--play .item {
    margin-bottom: 15px;
  }
}
@media only screen and (min-width: 1000px) {
  .events-type--play .item {
    flex: 0 0 45px;
    margin-right: 15px;
  }
}
@media only screen and (max-width: 499px) {
  .events-type--calendar {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (min-width: 500px) {
  .events-type--calendar .item {
    flex: 0 0 45px;
    margin-right: 15px;
  }
  .events-type--calendar .title {
    flex: 1;
  }
}
.events-type--calendar .item {
  margin-bottom: 0;
  transition: background-color 0.4s ease-in-out;
}
.events-type--calendar .time {
  margin-right: 15px;
}
.events-type--calendar:hover .item {
  background-color: #1a81e0;
}
/*# sourceMappingURL=css/events-type.css.map */